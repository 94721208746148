import { Elements } from "@stripe/react-stripe-js";
import { useQuery } from "@tanstack/react-query";
import { Column, Label, Spinner, Text, Title } from "@yolaw/ui-kit-components";
import { LockFlat } from "@yolaw/ui-kit-icons";
import Modal, { CustomHTMLDialogElement } from "components/modal/Modal";
import PaymentFormContext from "components/payment-form/PaymentFormContext";
import PaymentMethodIcons from "components/payment-form/PaymentMethodIcons";
import PaymentMethodSelector from "components/payment-form/PaymentMethodSelector";
import { ModalName } from "contexts/app";
import { useApp, useIsMobile, useLegalEntity, useSegment } from "hooks";
import StripeProvider from "providers/stripe";
import { useEffect, useReducer, useRef } from "react";
import { SubscriptionsService } from "services";
import styled, { css } from "styled-components";
import { SubscriptionPlan } from "types/subscriptions";
import { TOC_LINKS } from "utils/constants";
import NumberUtils from "utils/number";
import TranslatorUtils from "utils/translator";
import PaymentModalContext from "./Context";
import PayConfirmButton from "./PayConfirmButton";
import Hint from "components/Hint";

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    .modal-container-inner {
      @media (min-width: ${theme.breakpoints.l}px) {
        max-width: ${theme.breakpoints.l}px;
      }
    }
  `}
`;

const StyledTitle = styled(Title)`
  text-align: center;
`;

const PayWithContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @container (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0;
  }
`;

const StyledLabel = styled(Label)`
  text-align: center;
  svg {
    display: initial;
  }
`;

const PadlockIcon = styled(LockFlat)`
  vertical-align: top;
`;

const TOSLink = styled.a`
  color: ${({ theme }) => theme.colors.neutral.light};
`;

const Container = styled<React.ElementType>(Column)`
  > .actions_container {
    text-align: center;
  }
  max-width: 768px;
  margin: 0 auto;
`;

const ContentContainer = styled(Column)`
  max-width: 600px;
  margin: 0 auto;
`;

const LoaderContainer = styled(Column)`
  align-self: center;
  align-items: center;
  min-width: 80px;
`;

const PlanInfo = styled.div`
  text-align: center;
`;

const PayButtonContainer = styled.div`
  text-align: center;
`;

const LSCPStandalonePaymentModal = () => {
  const app = useApp();
  const isMobile = useIsMobile();
  const currentLegalEntity = useLegalEntity();
  const segment = useSegment();

  const plan = app.openingModal?.extraData?.plan as SubscriptionPlan;
  if (!plan) {
    throw new Error("Missing `plan` in extraData");
  }

  const [paymentModalState, paymentModalDispatch] = useReducer(
    PaymentModalContext.reducer,
    PaymentModalContext.initialState
  );
  const modalRef = useRef<CustomHTMLDialogElement | null>(null);

  const [paymentFormState, paymentFormDispatch] = useReducer(
    PaymentFormContext.reducer,
    PaymentFormContext.initialState
  );

  const { paymentError } = paymentFormState;
  const tierToSell = SubscriptionsService.getTierToSell(plan);
  const familyDisplayName = SubscriptionsService.getFamilyDisplayName(plan);
  const buttonText = `M’abonner à ${familyDisplayName}`;

  const getPaymentMethodsQuery = useQuery({
    queryKey: ["paymentMethods", app.currentLegalEntityId],
    queryFn: app.getPaymentMethods,
  });

  const { isPending: isFetchingPaymentMethods, data: paymentMethods } =
    getPaymentMethodsQuery;

  useEffect(() => {
    if (paymentMethods) {
      app.setPaymentMethods(paymentMethods);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  useEffect(() => {
    paymentModalDispatch({
      type: PaymentModalContext.Action.SetModalRef,
      payload: modalRef,
    });
  }, [modalRef]);

  useEffect(() => {
    const defaultPaymentMethod =
      currentLegalEntity.paymentMethod?.payment_method;
    const defaultPaymentMethodCard =
      currentLegalEntity.paymentMethod?.payment_method?.card;

    if (defaultPaymentMethod && defaultPaymentMethodCard) {
      paymentFormDispatch({
        type: PaymentFormContext.Action.SetSavedPaymentMethods,
        payload: [
          {
            ...defaultPaymentMethodCard,
            payment_method_id: defaultPaymentMethod.id,
            is_default_payment_method: true,
          },
        ],
      });
    }
  }, [currentLegalEntity.paymentMethod?.payment_method]);

  useEffect(() => {
    if (app.openingModal?.name === ModalName.LSCPStandalonePayment) {
      segment.track("paywall: displayed", {
        context: app.openingModal?.openedBy?.context,
        from: app.openingModal?.openedBy?.from,
        "subscription family": plan.family.slug,
        "subscription product": plan.product.slug,
        "subscription plan": plan.slug,
        step: "form",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app.openingModal]);

  const closeModal = () => {
    app.closeModal();
  };

  return (
    <StyledModal
      isOpen={app.openingModal?.name === ModalName.LSCPStandalonePayment}
      onClose={closeModal}
      ref={modalRef}
      isLocked={paymentModalState.isSubmitting}
    >
      <Elements
        stripe={StripeProvider.stripePromise}
        options={{ mode: "setup", currency: "eur", amount: 0 }}
      >
        <PaymentModalContext.Context.Provider
          value={{ state: paymentModalState, dispatch: paymentModalDispatch }}
        >
          <PaymentFormContext.Context.Provider
            value={{ state: paymentFormState, dispatch: paymentFormDispatch }}
          >
            <Container>
              <StyledTitle type={isMobile ? "H1" : "H2"}>
                M’abonner à {familyDisplayName}
              </StyledTitle>

              <ContentContainer>
                <PayWithContainer>
                  <Title type="H4">Payer avec</Title>
                  <PaymentMethodIcons />
                </PayWithContainer>

                <StyledLabel fontWeightVariant="bold">
                  <PadlockIcon /> SSL Secure Connection
                </StyledLabel>

                {isFetchingPaymentMethods ? (
                  <LoaderContainer>
                    <Spinner />
                    <Text type="small">
                      Chargement du moyen de paiement ...
                    </Text>
                  </LoaderContainer>
                ) : (
                  <PaymentMethodSelector />
                )}

                <Text type="small" color="neutral.dark" textAlign="left">
                  En cliquant sur &quot;{buttonText}&quot; vous attestez avoir
                  lu et accepté les{" "}
                  <TOSLink
                    href={TOC_LINKS.CGU}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Conditions Générales
                  </TOSLink>{" "}
                  et la{" "}
                  <TOSLink
                    href={TOC_LINKS.PRIVACY}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    charte de la vie privée
                  </TOSLink>
                  .
                </Text>

                <PlanInfo>
                  <Text fontWeightVariant="bold" type="body">
                    {NumberUtils.currencyFormat(tierToSell.flat_price)} HT/
                    {TranslatorUtils.translate(plan.period_unit)}, soit{" "}
                    {NumberUtils.currencyFormat(tierToSell.total_price_it || 0)}{" "}
                    TTC/
                    {TranslatorUtils.translate(plan.period_unit)}.
                  </Text>

                  <Text fontWeightVariant="bold" type="body">
                    Premier paiement une fois {familyDisplayName} activé.
                  </Text>
                </PlanInfo>

                {paymentError && (
                  <Hint
                    type="error"
                    title={`Désolé, vous n'êtes pas éligible à ${familyDisplayName}.`}
                  />
                )}

                <PayButtonContainer>
                  <PayConfirmButton plan={plan} buttonText={buttonText} />
                </PayButtonContainer>
              </ContentContainer>
            </Container>
          </PaymentFormContext.Context.Provider>
        </PaymentModalContext.Context.Provider>
      </Elements>
    </StyledModal>
  );
};

export default LSCPStandalonePaymentModal;
