import { Column, Title } from "@yolaw/ui-kit-components";
import { addDays, isBefore } from "date-fns";
import { useSegment } from "hooks";
import ZenWebinarBanner from "pages/components/Banners/ZenWebinarBanner";
import { ZenDashboard } from "pages/zen/context";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ZenUpsellBanner from "../../components/ZenUpsellBanner";
import useZenProject from "../../hooks/useZenProject";
import DiscoverAdministrativeDashboardBanner from "./DiscoverAdministrativeDashboardBanner";
import LegalObligationTaskSection from "./LegalObligationTaskSection";
import Hint from "components/Hint";

const Container = styled(Column)`
  && {
    row-gap: ${({ theme }) => theme.spacing.l}px;
  }
`;

const LegalObligationsDashboard = () => {
  const project = useZenProject();
  const segment = useSegment();
  const [searchParams] = useSearchParams();
  const [zenWebinarBannerRendered, setZenWebinarRendered] = useState(false);

  const zenWebinarBannerRef = useCallback((node: HTMLDivElement | null) => {
    setZenWebinarRendered(node !== null);
  }, []);

  const zenWebinarTask = project.findTaskBySlug("webinaire");

  /* Only display this banner if the Zen Webinar task available and has not been done */
  const shouldDisplayZenWebinarBanner =
    zenWebinarTask && !zenWebinarTask.is_done;

  const shouldDisplayDiscoverAdministrativeDashboardBanner =
    // project is "post-siren"
    project.hasCompanySiren &&
    // Zen Webinar banner is not rendered
    !zenWebinarBannerRendered &&
    // No overdue task
    project.legalObligationTasks.overdueTasks.length === 0 &&
    // No todo task due in the next 15 days
    !project.legalObligationTasks.todoTasks.some(
      (task) =>
        task.due_date && isBefore(task.due_date, addDays(new Date(), 15))
    );

  useEffect(() => {
    project.setCurrentDashboard(ZenDashboard.LegalObligationCalendar);

    segment.track("zen legal obligations calendar: displayed", {
      from: searchParams.get("utm_source") || "userspace",
      project_id: project.info.id,
      has_siren: project.hasCompanySiren,
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Title type="H1">Calendrier des obligations</Title>

      {project.isZenSubscriptionNeeded ? (
        <ZenUpsellBanner />
      ) : (
        <>
          {shouldDisplayZenWebinarBanner && (
            <ZenWebinarBanner
              context={project.currentDashboard}
              ref={zenWebinarBannerRef}
            />
          )}
          {shouldDisplayDiscoverAdministrativeDashboardBanner && (
            <DiscoverAdministrativeDashboardBanner />
          )}

          {!project.hasCompanySiren && (
            <Hint
              type="information"
              title="Certaines tâches seront accessibles une fois votre SIREN reçu."
            />
          )}
        </>
      )}

      <LegalObligationTaskSection
        defaultOpen={false}
        title={"Obligations effectuées"}
        tasks={project.legalObligationTasks.doneTasks}
      />

      {project.hasCompanySiren ? (
        <>
          <LegalObligationTaskSection
            title={"Obligations en retard"}
            tasks={project.legalObligationTasks.overdueTasks}
          />
          <LegalObligationTaskSection
            title={"Obligations à faire"}
            tasks={project.legalObligationTasks.todoTasks}
          />
        </>
      ) : (
        <LegalObligationTaskSection
          title={"Obligations à faire"}
          tasks={project.legalObligationTasks.preSirenTasks.concat(
            project.legalObligationTasks.postSirenTasks
          )}
        />
      )}
    </Container>
  );
};

export default LegalObligationsDashboard;
