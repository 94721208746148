import axios, { isAxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useLegalEntity, usePrevious } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import { LoadingPage } from "pages/loading";
import { LegacySubscriptionSlug } from "types/legacy-subscriptions";
import useZenProject from "./hooks/useZenProject";
import Hint from "components/Hint";

const ZenProjectInitializer: React.FC = () => {
  const navigate = useNavigate();
  const project = useZenProject();
  const currentLegalEntity = useLegalEntity();
  const zenSub = currentLegalEntity.findLegacySubscription(
    LegacySubscriptionSlug.Zen
  );

  const [error, setError] = useState<string | null>(null);
  const prevZenSubStatus = usePrevious(zenSub?.status);

  const _getProject = async () => {
    try {
      // Try getting project first
      await project.getProjectInfo(String(currentLegalEntity.funnel_id));
      // Clear error
      setError(null);
      return Promise.resolve();
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const _initiateProject = async () => {
    try {
      // try to initiate new Zen project via Zen Access URL
      const accessURL = await currentLegalEntity.getZenAccessURL();
      if (accessURL) {
        // Call the redirection URL to trigger the project creation
        await axios(accessURL, {
          validateStatus: (status) => [301, 302, 401].includes(status),
        });
      } else {
        throw new Error("Vous n'êtes pas éligible pour accéder à Zen !", {
          cause: "NO_ACCESS_PERMISSION",
        });
      }
    } catch (error: any) {
      if (error.cause === "NO_ACCESS_PERMISSION") {
        // setError(error.message);
        // go to home page
        navigate("/");
      } else {
        // Blindly get project
        await _getProject().catch(() =>
          setError("Impossible de lancer le projet Zen !")
        );
      }
    }
  };

  const _getOrInitiateProject = async () => {
    try {
      // Try getting project first
      await _getProject();
    } catch (error: any) {
      if (
        isAxiosError(error) &&
        // Looks like the user and project have never been created in Zen BE yet
        ((error.response?.status === 401 &&
          error.response?.data.code === "user_not_found") ||
          // OR User is created but his project is not available (user owns more than one project)
          error.response?.status === 404)
      ) {
        await _initiateProject();
      } else {
        setError("Impossible d'obtenir le projet Zen !");
      }
    }
  };

  useEffect(() => {
    if (currentLegalEntity?.funnel_id) {
      _getOrInitiateProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLegalEntity?.funnel_id]);

  useEffect(() => {
    if (project.info.id) {
      project.getTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.info.id]);

  useEffect(() => {
    if (project.info.id && zenSub && prevZenSubStatus !== zenSub.status) {
      project.refreshProjectAndTasks();
    }
  }, [prevZenSubStatus, project, zenSub, zenSub?.status]);

  if (error)
    return (
      <PageContainer>
        <Hint type="error" title={error} />
      </PageContainer>
    );

  // Project is not ready (no info)
  if (!project.info.id) return <LoadingPage />;

  return <Outlet />;
};

export default ZenProjectInitializer;
