import { Column, Spinner, Text, Title } from "@yolaw/ui-kit-components";
import { CopyFlat } from "@yolaw/ui-kit-icons";
import { ModalName } from "contexts/app";
import { useApp } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { FunnelService } from "services";
import styled, { css } from "styled-components";
import { FunnelWireTransferDetails } from "types/formalities";
import NumberUtils from "utils/number";
import FunnelWireTransferChangePaymentMethodModal from "./ChangePaymentMethodModal";
import Hint from "components/Hint";

const Container = styled.div`
  ${({ theme }) => css`
    max-width: 564px;

    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing.s}px;

    padding: ${theme.spacing.s}px;
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.blue.small};

    .iban-bic {
      display: flex;
      flex-direction: column;
      row-gap: inherit;

      @container (min-width: ${theme.breakpoints.m}px) {
        flex-direction: row;
        column-gap: ${theme.spacing.xs}px;

        .iban {
          flex: 2;
        }
        .bic {
          flex: 1;
        }
        .iban-bic--divider {
          display: none;
        }
      }
    }

    .with-copy-button {
      display: flex;
      column-gap: ${theme.spacing.xs}px;
      justify-content: space-between;

      .copy-button {
        all: unset;
        cursor: pointer;
        .copy-button--inner {
          display: flex;
          column-gap: ${theme.spacing.xxxxs}px;
          align-items: center;
          flex-shrink: 0;
        }
      }

      @container (min-width: ${theme.breakpoints.m}px) {
        display: inline;
        > * {
          display: inline;
        }
        .copy-button {
          margin-left: ${theme.spacing.xs}px;
        }
      }
    }

    .link-button {
      all: unset;
      cursor: pointer;
      text-decoration: underline;
    }

    @container (min-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.m}px;
    }
  `}
`;

type CopyButtonProps = {
  data: string | undefined;
  copiedData: string | undefined;
  onCopy: (data: string) => void;
};
const CopyButton = ({ data, copiedData, onCopy }: CopyButtonProps) => {
  const handleCopy = () => {
    if (data) {
      navigator.clipboard.writeText(data);
      onCopy(data);
    }
  };

  return (
    <button className="copy-button" onClick={handleCopy}>
      <div className="copy-button--inner">
        <Text type="small" fontWeightVariant="bold">
          {copiedData && copiedData === data ? "Copié" : "Copier"}
        </Text>
        <CopyFlat />
      </div>
    </button>
  );
};

type Props = {
  funnelId: number;
  onError: (errorMessage: string) => void;
};
const WireTransferInfoCard = ({ funnelId, onError }: Props) => {
  const app = useApp();
  const [wireTransferDetails, setWireTransferDetails] = useState<
    FunnelWireTransferDetails | undefined
  >();
  const [copiedData, setCopiedData] = useState<string | undefined>();

  const _getWireTransferDetails = useCallback(async () => {
    try {
      const result = await FunnelService.getFunnelWireTransferDetails(funnelId);
      setWireTransferDetails(result);
    } catch (error: any) {
      onError(error.message);
    }
  }, [funnelId, onError]);

  useEffect(() => {
    _getWireTransferDetails();
  }, [_getWireTransferDetails]);

  if (!wireTransferDetails) {
    return <Spinner />;
  }

  const {
    currency,
    total_amount,
    paid_amount,
    remaining_amount,
    wire_transfer_reference,
    financial_addresses,
  } = wireTransferDetails;

  const ibanFinalcialAddress = financial_addresses?.find(
    (fa) => fa.type === "iban" && fa.iban
  );
  const { account_holder_name, bic, iban } = ibanFinalcialAddress?.iban || {};

  const openConfirmationModal = () => {
    app.openModal({ name: ModalName.FunnelWireTransferChangePaymentMethod });
  };

  return (
    <>
      <Container>
        <Column>
          <Title type="H4">Montant à payer</Title>

          <div>
            <Text type="body">
              Total&nbsp;: {NumberUtils.currencyFormat(total_amount, currency)}
            </Text>
            {paid_amount ? (
              <Text type="body">
                Déjà payé&nbsp;:{" "}
                {NumberUtils.currencyFormat(paid_amount, currency)}
              </Text>
            ) : null}
            <Text type="body" fontWeightVariant="bold">
              Restant à payer&nbsp;:{" "}
              {NumberUtils.currencyFormat(remaining_amount, currency)}
            </Text>
          </div>

          <hr />

          <div>
            <Text color="neutral.darker">
              Envoyer votre virement en précisant dans le libellé de la
              transaction la référence&nbsp;:{" "}
            </Text>
            <span className="with-copy-button">
              <strong>{wire_transfer_reference}</strong>
              <CopyButton
                data={wire_transfer_reference}
                copiedData={copiedData}
                onCopy={setCopiedData}
              />
            </span>
          </div>

          <Title type="H4">Informations</Title>

          <div>
            <div className="with-copy-button">
              <Text type="body" fontWeightVariant="bold">
                Nom du bénéficiaire
              </Text>
              <CopyButton
                data={account_holder_name}
                copiedData={copiedData}
                onCopy={setCopiedData}
              />
            </div>
            <Text type="body">{account_holder_name}</Text>
          </div>

          <hr />

          <div className="iban-bic">
            <div className="iban">
              <div className="with-copy-button">
                <Text type="body" fontWeightVariant="bold">
                  IBAN
                </Text>
                <CopyButton
                  data={iban}
                  copiedData={copiedData}
                  onCopy={setCopiedData}
                />
              </div>
              <Text type="body">{iban}</Text>
            </div>

            <hr className="iban-bic--divider" />

            <div className="bic">
              <div className="with-copy-button">
                <Text type="body" fontWeightVariant="bold">
                  BIC
                </Text>
                <CopyButton
                  data={bic}
                  copiedData={copiedData}
                  onCopy={setCopiedData}
                />
              </div>
              <Text type="body">{bic}</Text>
            </div>
          </div>
          <hr />
        </Column>

        <Column style={{ alignItems: "center" }}>
          <Hint
            type="information"
            title="Le virement peut être reçu sous 2 à 4 jours ouvrés. Une fois les fonds crédités, vous pourrez accéder à votre démarche."
          />
          {!paid_amount && (
            <button className="link-button" onClick={openConfirmationModal}>
              Modifier le moyen de paiement
            </button>
          )}
        </Column>
      </Container>

      {app.openingModal?.name ===
        ModalName.FunnelWireTransferChangePaymentMethod && (
        <FunnelWireTransferChangePaymentMethodModal funnelId={funnelId} />
      )}
    </>
  );
};

export default WireTransferInfoCard;
