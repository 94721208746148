import { forwardRef } from "react";
import { MessageBox, MessageBoxType } from "@yolaw/ui-kit-components";

type HintProps = {
  type: MessageBoxType;
  title: string;
  content?: string;
  className?: string;
};

const Hint: React.FC<HintProps> = forwardRef<HTMLDivElement, HintProps>(
  function Hint({ type, title, content, className }, ref) {
    return (
      <MessageBox
        ref={ref}
        type={type}
        sections={[{ title, content }]}
        className={className}
      />
    );
  }
);

export default Hint;
