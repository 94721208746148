import {
  BarRadioButtons,
  Button,
  Column,
  Text,
  Title,
} from "@yolaw/ui-kit-components";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { useIsMobile } from "hooks";
import { BackButton } from "pages/components";
import { ELEMENT_IDS } from "pages/zen/constants";
import useERecordRoutes from "pages/zen/pages/mandatory-records/hooks/useERecordRoutes";
import useDecision from "../../hooks/useDecision";
import BottomStickyBar from "../components/BottomStickyBar";
import DecisionViewContext from "../context";
import useDecisionViewContext from "../hooks/useDecisionViewContext";
import DocumentValidationUnqualifiedModal from "./DocumentValidationUnqualifiedModal";
import AddDocumentPageContainer from "./components/AddDocumentPageContainer";
import FooterBackButton from "./components/FooterBackButton";
import { AddDocumentPaths } from "./routes";
import Hint from "components/Hint";

const ValidationChecksForm = styled(Column)`
  ${({ theme }) => css`
    && {
      row-gap: ${theme.spacing.s}px;
    }
  `};
`;

const WarningHint = styled(Hint)`
  max-width: 600px;
`;

const SET_VALUE_OPTIONS = {
  shouldValidate: true,
  shouldDirty: true,
  shouldTouch: true,
};

enum AnswerValue {
  Yes = "YES",
  No = "NO",
}
enum FieldName {
  isAttendanceSheetOrReportDocument = "isAttendanceSheetOrReportDocument",
  isDocumentSigned = "isDocumentSigned",
}
type FormInputs = {
  [FieldName.isAttendanceSheetOrReportDocument]: AnswerValue;
  [FieldName.isDocumentSigned]: AnswerValue;
};

const DocumentValidation = () => {
  const navigate = useNavigate();
  const decision = useDecision();
  const isMobile = useIsMobile();
  const { goToDecisionsPage } = useERecordRoutes();
  const decisionViewContext = useDecisionViewContext();

  const { control, formState, resetField, setValue, watch } =
    useForm<FormInputs>({
      mode: "onTouched",
    });
  const { isValid } = formState;
  const watchIsAttendanceSheetOrReportDocument = watch(
    FieldName.isAttendanceSheetOrReportDocument
  );
  const watchIsDocumentSigned = watch(FieldName.isDocumentSigned);

  const decisionHasDocument = decision?.visibleDocuments.length;

  const handleDocumentTypeAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(
      FieldName.isAttendanceSheetOrReportDocument,
      event.target.value as AnswerValue,
      SET_VALUE_OPTIONS
    );
    resetField(FieldName.isDocumentSigned);
  };

  const handleDocumentSignedAnswerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(
      FieldName.isDocumentSigned,
      event.target.value as AnswerValue,
      SET_VALUE_OPTIONS
    );
  };

  const answersAreNotQualified =
    watchIsAttendanceSheetOrReportDocument === AnswerValue.Yes &&
    watchIsDocumentSigned === AnswerValue.No;

  const goToDocumentSelection = () => {
    navigate(AddDocumentPaths.selectFile);
  };

  const displayDocumentValidationUnqualifiedModal = () => {
    decisionViewContext.openModal({
      id: DecisionViewContext.DocumentModal.DocumentValidationUnqualifiedModal,
      context: {},
    });
  };

  return (
    <>
      <AddDocumentPageContainer className="document-validation-container">
        {isMobile && <BackButton />}
        <div className="page-body">
          <Column>
            <Title type="H2">Valider l’ajout du document</Title>
            <Text type="small" color="neutral.darker">
              Avant de télécharger votre document, il est important de vérifier
              qu’il est bien conforme.
            </Text>
          </Column>

          <ValidationChecksForm as="form" id="DecisionDocumentValidationForm">
            <Controller
              control={control}
              name={FieldName.isAttendanceSheetOrReportDocument}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <BarRadioButtons
                  name={field.name}
                  label="Est-ce que votre document est une feuille de présence ou un procès-verbal ?"
                  options={[
                    {
                      label: "Oui",
                      value: AnswerValue.Yes,
                      selectedColor: "primary.main",
                    },
                    {
                      label: "Non",
                      value: AnswerValue.No,
                      selectedColor: "primary.main",
                    },
                  ]}
                  onChange={handleDocumentTypeAnswerChange}
                  value={field.value}
                />
              )}
            />

            {watchIsAttendanceSheetOrReportDocument === AnswerValue.Yes && (
              <>
                <Controller
                  control={control}
                  name={FieldName.isDocumentSigned}
                  rules={{
                    required: false,
                    validate: (value) => value === AnswerValue.Yes,
                  }}
                  render={({ field }) => (
                    <BarRadioButtons
                      name={field.name}
                      label="Est-ce que votre document est signé ?"
                      options={[
                        {
                          label: "Oui",
                          value: AnswerValue.Yes,
                          selectedColor: "primary.main",
                        },
                        {
                          label: "Non",
                          value: AnswerValue.No,
                          selectedColor: "error.dark",
                        },
                      ]}
                      onChange={handleDocumentSignedAnswerChange}
                      value={field.value}
                    />
                  )}
                />
                {watchIsDocumentSigned === AnswerValue.No && (
                  <WarningHint
                    type="error"
                    title="Votre feuille de présence et/ou votre procès-verbal doivent être signés pour être conformes."
                    content="Vous pouvez signer électroniquement votre document avec le site [Yousign](https://yousign.com/fr-fr) ou [DocuSign](https://www.docusign.com/fr-fr)."
                  />
                )}
              </>
            )}
          </ValidationChecksForm>
        </div>
        <BottomStickyBar id={ELEMENT_IDS.STICKY_BOTTOM_BAR}>
          {!isMobile && <FooterBackButton />}

          {answersAreNotQualified ? (
            decisionHasDocument ? (
              <Button onClick={displayDocumentValidationUnqualifiedModal}>
                Continuer
              </Button>
            ) : (
              <Button onClick={goToDecisionsPage}>Annuler</Button>
            )
          ) : (
            <Button disabled={!isValid} onClick={goToDocumentSelection}>
              Suivant
            </Button>
          )}
        </BottomStickyBar>
      </AddDocumentPageContainer>

      <DocumentValidationUnqualifiedModal
        open={
          decisionViewContext.openingModal?.id ===
          DecisionViewContext.DocumentModal.DocumentValidationUnqualifiedModal
        }
      />
    </>
  );
};

export default DocumentValidation;
