import Hint from "components/Hint";

const title = "Vous n’avez pas encore créé votre première décision.";

const content = `
  Le registre des décisions est une obligation légale.
  Les cas de création de décisions sont :
  * Ajouter un 1er dirigeant
  * Conserver toutes vos décisions prises au cours d’une assemblée générale
  * Modifier vos statuts (siège social, dirigeant...)
`;

const EmptyListMessageBox = () => {
  return (
    <div className="message-box-container">
      <Hint type="information" title={title} content={content} />
    </div>
  );
};

export default EmptyListMessageBox;
