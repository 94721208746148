import { ErrorCode as DropzoneErrorCode } from "react-dropzone";
import Hint from "components/Hint";

export const MAX_FILE_SIZE = 5; // MB

export enum ValidationErrorCode {
  PasswordProtected = "password-protected",
  EncryptedFile = "encrypted-file",
}

export type FileErrorCode = DropzoneErrorCode | ValidationErrorCode | string;

const getHintDetails = (errorCode: FileErrorCode | null) => {
  switch (errorCode) {
    case DropzoneErrorCode.FileInvalidType:
      return {
        title:
          "Vous ne pouvez pas importer ce type de fichier. Nous acceptons seulement le PDF.",
      };
    case DropzoneErrorCode.TooManyFiles:
      return { title: "Veuillez sélectionner 1 seul fichier." };
    case DropzoneErrorCode.FileTooLarge:
      return {
        title: `Ce fichier est trop volumineux (+ de ${MAX_FILE_SIZE}Mo)`,
        content:
          "* Compressez le fichier gratuitement avec le site [PDF compression de document](https://www.adobe.com/fr/acrobat/online/compress-pdf.html)",
      };
    case ValidationErrorCode.PasswordProtected:
    case ValidationErrorCode.EncryptedFile:
      return {
        title: "Le PDF téléchargé est en lecture seule",
        content:
          "Modifiez les autorisations de votre document pour autoriser les modifications et essayer à nouveau.\n\nSi besoin, contactez-nous via le bouton “Besoins d’aide ?” en haut à droite.",
      };
    default:
      return {
        title: "Désolé, l'ajout du fichier a échoué. Vous pouvez recommencer.",
      };
  }
};

type ErrorBoxProps = {
  errorCode: FileErrorCode | null;
};

const DocumentSelectionError = ({ errorCode }: ErrorBoxProps) => {
  if (!errorCode) return null;

  const { title, content } = getHintDetails(errorCode);

  return <Hint type="error" title={title} content={content} />;
};

export default DocumentSelectionError;
